.modal.show .modal-dialog {
    animation: slide-down 0.5s ease-out forwards;
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

.modal-content.envelope {
    border: 2px solid #007bff;
    border-radius: 10px;
    overflow: hidden;
    background: linear-gradient(135deg, #fff, #f0f0f0);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}
