.card{
 color: #2f2f2f;
 margin-bottom:3em;
}
.wrapped-table td {
 word-wrap: break-word;
 white-space: normal;
 max-width: 150px; /* Adjust this value as needed */
}

.table-responsive {
 overflow-x: auto;
}
.numbers{
 text-align: center;
}
.hero-video-container {
 position: relative;
 width: 100%;
 height: auto;
}

.center-button {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 z-index: 10; /* Make sure the button is above the image */
}

.hero-video {
 width: 100%;
 height: auto;
}
.equal-height-container {
 display: flex;
 flex-direction: column;
 height: 100%;
}


.roku-channels-card-body {
 flex-grow: 1;
 display: flex;
 flex-direction: column;
 justify-content: center;
}

.card-container {
 display: flex;
 flex-direction: column;
 height: 100%;
}

.card-header {
 flex-shrink: 0;
}

.card-body {
 flex-grow: 1;
 display: flex;
 flex-direction: column;
}

.card-footer {
 flex-shrink: 0;
}



.teamstream-card-info {
 overflow: hidden;
 text-overflow: ellipsis;
}

.card-body {
 max-width: 100%;
}


.roku-card-header{
 background-color: #6f1ab1;
 color:white;
 text-align: center;
 border-width: 1em;
 border-style: revert;
}
.teamstream-card-header{
 background-color: orange;
 color:white;
 text-align: center;
}
.roku-c-body{
 height:16em;
 background-image: linear-gradient(to right, rgb(224, 195, 252), rgb(142, 197, 252));
 color:white;
 border-width: .3em;
 border-style: solid;
 border-color: inherit;
}
.card-stats{
 text-align:center;
}
.roku-row{
 margin-top: 5em;
 margin-bottom: 5em;
}
.button-spacing .btn:not(:last-child) {
 margin-right: 1em;  /* Right margin of 1em */
}
.teamstream-card-container{
 background-color: transparent;
 border-color: transparent;
}

.container {
 display: flex;
 flex-wrap: wrap;
 justify-content: space-between;
}
/* Container for the cards */
.card-container {
 display: flex;
 flex-direction: column;
 height: 100%;
}

/* Ensure the cards take up equal space */
.roku-channels-card-body,
.teamstream-card-info {
 flex: 1;
}

/* Optional: Ensure both cards are the same height */
.roku-channels-card-body {
 background-image: linear-gradient(to right, rgb(67, 206, 162), rgb(24, 90, 157));
 color: white;
}

.teamstream-card-info {
 background-image: linear-gradient(to right, rgb(255, 126, 95), rgb(254, 180, 123));
 color: white;
 border-color: white;
 border-style: solid;
 font-weight: 800;
 padding: .75rem 1.25rem;
}

.column {
 flex: 1;
 min-width: 250px; /* Minimum width for each column to avoid squeezing too much */
 margin: 10px;
}

.button-group button {
 display: block;
 width: 100%;
 margin-top: 10px;
 padding: 8px;
 border: none;
 border-radius: 4px;
 background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
 color: white;
 cursor: pointer;
}

.button-group button:hover {
 opacity: 0.8;
}
.roku-channel-container{
 border-color:#de4e7f;
 corder-width: .2em;
 border-style: solid;
}

.roku-selection {
 text-decoration: none;
 text-align: center;
}
@media only all and (max-width: 1200px) {

 img {
  border:none
 }
}


@media (max-width: 768px) {
 .container {
  flex-direction: column;
 }

 .column {
  width: 100%; /* Each column takes full width on small screens */
 }
}

