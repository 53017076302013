@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MyCustomFont';
  src: url('./EvanstonTavern-1893BoldInline/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MyCustomFont2';
  src: url('./EvanstonTavern-1893BoldInline/SF-Pro-Display-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'MyCustomFont3';
  src: url('./EvanstonTavern-1893BoldInline/SF-Pro-Display-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}



body {
  margin: 0;
  font-family: 'Playfair Display', serif;
  font-size: 14px;
  color: white;
  background-color: #f9f7f1;
}
header {
  color:white;
  padding-top: .5em;
  padding-right: 1em;
  font-family: 'Playfair Display', serif;
  font-weight: 900;
  font-size: 2em;
  text-transform: uppercase;
  display: inline-block;
  line-height: 3rem;
  margin-bottom: 3em;

}



